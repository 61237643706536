.order-accordion {
  margin-bottom: 10px;
  width: 475px;
}

.accordion-header {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  display: flex;
  border-radius: 10px;
}

.accordion-content {
  padding: 10px;
}

.cart-items {
  display: flex;
  flex-direction: column;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.cart-item img {
  margin-right: 10px;
}

.margin-right {
  margin-right: 10px;
}

.margin-0 {
  margin: 0;
}

.date-filter {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    gap: 10px;
    justify-content: space-evenly;
  }
}

.margin-left-5 {
  margin-left: 5px;
}

.flex {
  display: flex;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-top-22 {
  margin-top: 22px;
}

.button-cleanFilters {
  border-radius: 50%;
  padding: 8px;
  display: flex;
  background-color: white;
}

.filters-width {
  width: 155px !important;
}

.label-status {
  border: 2px solid;
  border-radius: 10px;
  padding: 4px;
}

.label-pending {
  border-color: #ffc107; /* Amarillo */
  color: #ffc107; /* Marrón oscuro para contraste */
}

.label-canceled {
  border-color: #dc3545; /* Rojo */
  color: #dc3545;
}

.label-completed {
  border-color: #28a745; /* Verde */
  color: #28a745;
}

.checkbox-select-status {
  right: 0;
  display: flex;
  position: absolute;
  margin-right: 20px;
  transform: scale(1.5);
}

.margin-top-80 {
  margin-top: 80px;
  // @media (min-width: 768px) {
  //   margin-left: 200px;
  //   margin-right: 200px;
  // }
}

.text-center {
  text-align: center;
}

.align-center {
  align-items: center;
}
