@import '../../app';

.margin-right-15 {
  margin-right: 15px !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: -25px;

  .dropdown-menu {
    @media (min-width: 768px) {
      margin-left: 15px;
    }
  }

  .dropdown-toggle {
    border-radius: 50%;
    background-color: white;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: $secondary-color;
    }

    .arrow {
      margin-left: 10px;
      transition: transform 0.3s ease;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 180px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 10px 0;
    z-index: 1;
    margin-left: 0;
  }

  .dropdown-item {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: left;

    &:hover {
      background-color: $secondary-color-clear;
    }
  }
}

.button-main {
  display: flex;
  align-items: center;
  gap: 15px;
  background: $secondary-color;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.2s ease;
  margin-left: 0;

  @media (min-width: 768px) {
    margin-left: 200px;
  }
}

.price-notice-position {
  display: flex;
  justify-content: center;
}
