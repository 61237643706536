$primary-color: #969696;
$secondary-color: #ff9f24;
$secondary-color-clear: #ffc166;

$main-color: #f5f5f5;

.main {
  margin-left: 10px;
  margin-right: 10px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-content {
  flex: 1;
}

@media (min-width: 768px) {
  .main {
    .main-content {
      flex: 2;
      margin-right: 20px;
    }
  }
}
