@import './../app';

.flex-column {
  display: flex;
  flex-direction: column;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.shopping-info-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-align-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.shopping-cart-header,
.shopping-cart-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;

  .product-name,
  .column-label.product {
    flex: 1.5;
    text-align: left;
    font-weight: bold;
  }

  .option-label,
  .column-label.option,
  .quantity,
  .column-label.quantity,
  .unit-price,
  .column-label.unit-price,
  .total-price,
  .column-label.total-price,
  button,
  .column-label.delete {
    flex: 1;
    text-align: center;
  }

  // Estilos específicos para precios y botones

  .total-price {
    color: $secondary-color;
    font-weight: bold;
  }

  button {
    background: none;
    border: none;
    color: #d9534f;
    font-size: 1.2em;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #c9302c;
    }
  }
}

// Estilos para el encabezado
.shopping-cart-header {
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #ccc;
}

.shopping-cart-item {
  display: flex;
  align-items: center;
}

.user-data-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 60px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.user-data-form label {
  font-weight: 500;
  color: #333;
  margin-right: 10px;
  width: 100px;
}

.input-field {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 5px;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #007bff;
  outline: none;
}

.margin-top-80 {
  margin-top: 80px;
}

.align-icon-text {
  display: flex;
  gap: 4px;
  align-items: center;
}

.margin-top-40 {
  margin-top: 40px;
}

// .margin-bottom--60 {
//   margin-bottom: -60px;
// }

.margin-bottom-40 {
  margin-bottom: -40px;
}

.font-18 {
  font-size: 18px !important;
}

.margin-horizontal-200 {
  margin-left: 0;
  margin-right: 0;

  @media (min-width: 968px) {
    margin-left: 200px;
    margin-right: 200px;
  }
}
