@import '../../app';

.login-prompt {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: $secondary-color;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 15px;

  @media (min-width: 768px) {
    margin-left: 210px;
  }
}

.right-section {
  display: flex;
  align-items: center;
}

.cart-container {
  position: relative;
}

.cart-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
}

.cart-dropdown h4 {
  margin: 0 0 10px 0;
}

.cart-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cart-dropdown li {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.cart-dropdown li:last-child {
  border-bottom: none;
}

.cart-icon-container {
  position: relative;
  display: flex;
  align-items: center;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: 4px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;

  @media (min-width: 768px) {
    right: 205px;
  }
}

.margin-responsive {
  margin-right: 10px;
  margin-left: 10px;

  @media (min-width: 768px) {
    margin-right: 210px;
  }
}
