.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.margin-top-4 {
  margin-top: 4px;
}

.timezone {
  width: 200px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 0.02em;
  border-color: #ff9f24;
  border-radius: 10px;

  @media (min-width: 768px) {
    width: 300px;
  }
}
