.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5; /* main-color */
}

.spinner {
  width: 80px;
  height: 80px;
  position: relative;
}

.spinner-circle {
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  border-top: 8px solid #ff9f24; /* secondary-color */
  border-right: 8px solid #ffc166; /* secondary-color-clear */
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.loading-text {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #969696; /* primary-color */
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
