@import '../../app';

.flex-welcome {
  display: flex;
  align-items: center;
}

button.add-to-cart {
  // all: unset;
  width: 100%;
  height: 50px;
  gap: 0px;
  border-radius: 12px;
  background-color: $secondary-color;
  color: white;
  border: none;
  font-size: 14px;
}

.add-to-cart:hover {
  background-color: #218838;
}

.price-notice {
  font-size: 1rem;

  font-style: italic;
  margin-bottom: 2rem; /* Espacio antes del listado de productos */
  padding: 8px;
  background-color: $secondary-color-clear;
  border-radius: 10px;
  text-align: center;
  color: white;
}

.quantity-input {
  width: 30px;
}

.quantity-select {
  width: 40px;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.lines-container {
  gap: 2px;
  display: flex;
  flex-direction: column;
}

.button-padding {
  padding-left: 10px;
}

.buttons-display {
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  bottom: 0;
  width: 100%;
}

.price {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.brand-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  margin-top: -50px;
  margin-bottom: 10px;
}

.buttons-position {
  background-color: $secondary-color;
  margin-top: 0;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  transition: transform 0.3s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.1);
}

// .header-container {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 20px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   width: 100%;
//   background-color: $secondary-color;
// }

/* Cuando el header está oculto, lo movemos hacia arriba */
.hidden {
  transform: translateY(-100%);
}

/* Cuando el header está visible, lo mantenemos en su lugar */
.visible {
  transform: translateY(0);
}

.hello-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: left;
  color: #646464 !important;
}

.welcome-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: left;
  margin: 0;
  color: #323232;
  margin-right: 10px;
}

.product-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 12px;
}

.product-price {
  color: $secondary-color;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
}

.quantity-input {
  width: 60px;
  padding: 4px;
  margin: 8px 0;
}

.product-weight {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #555;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-numbers {
  display: flex;
  gap: 10px;
}

.page-number {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-number:hover {
  background-color: #ddd;
}

.page-number.active {
  background-color: $secondary-color;
  color: #fff;
}

.margin-0 {
  margin: 0;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-right-10 {
  margin-right: 10px;
}

.flex-half {
  flex: 0.5;
}

.body-product {
  @media (min-width: 768px) {
    margin-left: 200px;
    margin-right: 200px;
  }
}

.product-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 768px) {
  .flex-half {
    flex: 1;
  }
}
