.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.button-upload {
  width: auto;
  height: 35px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: none;
  margin-left: 20px;
  background-color: #666363;
  color: white;
}

.flex-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.label-product-form {
  font-weight: 500;
  color: #333;
  margin-right: 10px;
  width: 100px;
}

.flex {
  display: flex;
}

.full-width {
  width: 100%;
  margin-right: 10px;
}

.input,
.textarea {
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 200px;
  padding: 6px;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.25);
  margin-top: 2px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 0.02em;
  border-color: #ff9f24;
}

@media (min-width: 768px) {
  .input,
  .textarea {
    width: 300px;
  }
}

// @media (max-width: 650px) {
//   .input,
//   .textarea {
//     width: 300px;
//   }
// }

.floating-button {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0f0be6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.floating-button:hover {
  background-color: #b0b0e0;
}

.avatar-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 10px;
}

.avatar-form {
  width: 150px;
  height: auto;
}
