@import './../app';

.input-field {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 5px;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  // border-color: #007bff;
  outline: none;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
  color: $secondary-color;
}

.primary-button {
  background-color: $secondary-color;
  border: none;
  padding: 8px;
  border-radius: 10px;
  color: white;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  // Hover
  &:hover {
    background-color: darken($secondary-color, 10%);
  }

  // Active (cuando se presiona el botón)
  &:active {
    background-color: darken($secondary-color, 20%);
    transform: scale(0.95);
  }

  // Disabled
  &:disabled {
    background-color: #d3d3d3; // Color gris
    cursor: not-allowed;
    color: #a8a8a8; // Texto gris claro
  }
}

.margin-top {
  margin-top: 10px;
}
