@import '../../app';

.margin-right-20 {
  margin-right: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.div-quantity-buttons {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  justify-content: space-around;
  margin-top: 5px;
  padding: 2px;
}

.quantity-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Espacio entre elementos */
  margin-top: 5px;
  margin-bottom: 5px;
}

.quantity-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  user-select: none;

  // &:hover {
  //   background-color: #e0e0e0;
  // }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.quantity-display {
  // width: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.product-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.product-name {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  margin-right: 16px;
}

.product-option {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 16px;
}

.quantity-input {
  width: 40px;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-align: center;
  margin-right: 16px;
}

.plus-button {
  background-color: $secondary-color;
  border: none;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border-radius: 8px 0px 8px 0px;
  opacity: 0px;

  color: white;
  font-size: 24px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e68900;
  }
}

.plus-sign {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}
