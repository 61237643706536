.footer {
  background-color: #000;
  color: #fff;
  padding: 1rem 0;
  width: 100%;
  position: relative;
  margin-top: 20px;
  clear: both;
  left: 0;
  right: 0;
}

.footer__content {
  display: flex;
  align-items: flex-start; /* Alinea los elementos al tope */
  justify-content: space-around;
  width: 100%; /* Asegura que el contenido ocupe todo el ancho disponible */
  margin: 0 auto; /* Centra el contenido horizontalmente */

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.footer__contact,
.footer__social {
  text-align: center;
  margin-bottom: 1.5rem;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0; /* Asegura que el título esté pegado al tope */
  }

  p {
    margin: 0;
    word-wrap: break-word; /* Corta palabras largas */
    overflow-wrap: break-word; /* Para soporte adicional */
  }
}

.footer__contact p:nth-of-type(2) {
  max-width: 200px; /* Ajusta el ancho máximo para limitar el espacio */
  margin: 0 auto; /* Centra el texto si se corta */

  @media (min-width: 768px) {
    max-width: 400px;
  }
}

.footer__social-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #ccc;
  }
}

.footer__icon {
  font-size: 1.5rem;
}

.flex-align-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
