@import '../../app';

.lines-container {
  gap: 2px;
  display: flex;
  flex-direction: column;
}

.arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.arrow-left {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.dots {
  font-weight: bold;
  font-size: 22px;
  height: 100%;
}

.skus-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &.m-top {
    margin-top: 50px;
  }
}

.sku-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #646464;
}

.button-read-more {
  font-weight: 600;
  color: $secondary-color;
  border: none;
  background-color: transparent;
}

.product-brand {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: left;
}

.brand-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;
  text-align: left;
  display: flex;
  align-items: center;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.image-detail {
  height: 200px;
  object-fit: contain;
}

.stock {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
  text-align: left;
  color: $primary-color;
}

.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.lock-icon {
  font-size: 18px;
}

.button {
  border: none;
  border-radius: 50%;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.2s ease;
}

.button:hover {
  background-color: #f0f0f0; /* Fondo ligeramente más oscuro */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Suave sombra */
}

.button:active {
  transform: scale(0.95); /* Retroceso al hacer clic */
}

.line {
  background-color: black;
  display: block;
  width: 4px;
  height: 3px;
}

.line-middle {
  justify-content: left !important;
  width: 15px;
}

.line-top {
  width: 20px;
}

.line-bottom {
  width: 20px;
}

.whatsapp-icon {
  border: none;
  border-radius: 50%;
  background-color: #25d366;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;

  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.whatsapp-icon:hover {
  background-color: #1eb955;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
}

.whatsapp-icon:active {
  transform: scale(0.95);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.whatsapp-icon svg {
  color: #ffffff;
  font-size: 24px;
}
